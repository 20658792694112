import {Component} from "react";
import {Redirect} from "react-router-dom";
import ArtInfoTable from "./content/ArtInfoTable";
import DisplayAssets from "./content/DisplayAssets";
import FormattedText from "./content/FormattedText";

const infoTableSchema = [
    {
        source: "artist.name",
        displayName: "Name"
    },
    {
        source: "artist.locationName",
        displayName: "Country"
    },
    {
        source: "artist.web",
        displayName: "Web",
        clickable: true
    }
];

class ArtistCard extends Component {

    handleFieldClicked = field => {
        if(field !== 'artist.web'){
            return;
        }
        if(this.props.artist.web !== undefined) {
            window.open(this.props.artist.web,'_blank');
        }
    };

    render() {
        if(this.props.artist === undefined){
            // TODO: Change me to proper 404.
            return <Redirect to="/404"/>
        }



        return (
            <>
                <div className="infoCard">
                    <ArtInfoTable artist={this.props.artist} schema={infoTableSchema} handleClick={this.handleFieldClicked}/>
                    <div className="artContentContainer"> <DisplayAssets assets={this.props.artist.assets}/></div>
                    <div className="artDescription">
                        <h3>About {this.props.artist.name}</h3>
                        <FormattedText text={this.props.artist.bio} />
                    </div>
                </div>
            </>
        )
    }
}

export default ArtistCard;
