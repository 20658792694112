/*
 * Remember / note to self: no VR manipulation can be done in React! (perf reasons)
 * Use AFRAME component!
 */
import React, {Component} from 'react';
import Poster from "./Poster";
import "./DefaultVRScene.css"

const supportedDefaults = ['image', 'gltf'];

const defaultRadius = 2;

const vectorSum = (v1, v2) => {
    let result = [];
    for(let i = 0; i < v1.length; i++){
        result[i] = v1[i] + v2[i];
    }
    return result;
};

const radToDeg = rad => (rad / (2 * Math.PI)) * 360;

const getMaxBoxSize = (boxCount, boxCenterRadius) => {
    let r = Math.tan(Math.PI / boxCount);
    return 2 * boxCenterRadius * r / (1 + r)
};

class DefaultScene extends Component {
    static filterDisplayableAssets = assets => assets.filter(DefaultScene.isAssetDisplayable);
    static isAssetDisplayable = asset => {
        return supportedDefaults.includes(asset.type);
    };
    static canAssetsBeDisplayed = assets => DefaultScene.filterDisplayableAssets(assets).length !== 0;


    generateContent = asset => {
        if(asset.type === "image"){
            return <a-image src={asset.src} />
        } else if (asset.type === "gltf"){
            return <a-entity gltf-model={asset.object}/>
        }
        return null;
    };

    getPoints = (count, radius = defaultRadius) => {
        const start = [0, 0];
        let diff = 2 * Math.PI / count;
        let result = [];

        const boxSize = getMaxBoxSize(count, radius);

        for(let i = 0; i < count; i++){
            let alpha = diff * i;
            let point = [Math.cos(alpha) * radius, Math.sin(alpha) * radius];
            result.push({
                position: vectorSum(start, point),
                rotation: radToDeg(alpha),
                maxSize: boxSize
            });
        }

        return result;
    };

    render(){
        let assets = DefaultScene.filterDisplayableAssets(this.props.assets ?? []);

        let wallCount = assets.length;
        if(wallCount === 0) {
            return <div className="artContent"> <div className="replaceableContent">
                <Poster image="https://static.newfuture.industra.space/assets/arts/a95fe630-bb90-4b63-8742-aa016da69a99/cover.jpg">
                    <div className="outOfOrder">Probíhá kurátorská úprava 3D scény. Scéna bude brzy opět k dispozici.</div>
                </Poster>
            </div> </div>
        }

        wallCount = wallCount < 3 ? 3 : wallCount;
        wallCount = wallCount > 10 ? 10 : wallCount;



        let points = this.getPoints(wallCount);

        let content = [];

        for (let i = 0; i < assets.length && i < wallCount; i++) {
            let point = points[i];
            content.push(<a-entity position={point.position.join(" 1.6 ")}
                                     rotation={"0 " + (-point.rotation +270) + " 0"}
                                     scale={point.maxSize + " " + point.maxSize + " " + point.maxSize}>
                {this.generateContent(assets[i])}
            </a-entity>)
        }





        return <div className="artContent vrMode">
            <div className="vrContent">
                <a-scene embedded vr-mode-ui="enabled: true" loading-screen="dotsColor: white; backgroundColor: #ffb9ff" >
                    {content}
                    <a-entity environment="groundColor:#ffb9ff; groundTexture: none; ground: canyon; playArea: 1; skyType: atmosphere" />
                    <a-entity camera="fov: 45" />
                </a-scene>
            </div>
        </div>


    }
}

export default DefaultScene;
