import ContentChanger from "./ContentChanger";
import {Iframe} from "./BasicContent";
import Video from "./Video";
import Image from "./Image";
import Link from "./Link";
import OutOfOrder from "./OutOfOrder";
import GLTFObject from "./GLTFObject";

function getAssetGroups(assets) {
    let groups = []
    let mainAssets = []

    for(let asset of assets){
        if(asset.type === "iframe"){
            mainAssets.push(<Iframe key={asset.src} src={asset.src}/>);
        } else if(asset.type === "image"){
            mainAssets.push(<Image asset={asset}/>);
        } else if(asset.type === "video"){
            mainAssets.push(<Video key={asset.src} poster={asset.poster} src={asset.src} autoplay={asset.autoplay} />);
        } else if(asset.type === "link"){
            mainAssets.push(<Link poster={asset.poster} href={asset.href} text={asset.text}/>);
        } else if(asset.type === "gltf"){
            mainAssets.push(<GLTFObject object={asset.object} poster={asset.poster}/>);
        } else if(asset.type === "tempDisabled"){
            mainAssets.push(<OutOfOrder/>);
        } else if(asset.type === "assetGroup") {
            groups = groups.concat(getAssetGroups(asset.assets))
        }
    }
    groups.unshift(mainAssets)
    return groups
}

function DisplayAssets(props) {
    if(props?.assets === undefined){
        return <></>;
    }

    let assetGroups = getAssetGroups(props.assets);
    let changers = [];


    for(let assetGroup of assetGroups) {
        changers.push(<ContentChanger>
            {assetGroup}
        </ContentChanger>)
    }

    return <> {changers} </>
}

export default DisplayAssets;
