function InfoCard(){
    return <div>
        <h1>New Future</h1>

        <p>V době, kdy začal vznikat projekt NEW FUTURE, byl umělecký svět již uprostřed zuřivé transformace přizpůsobení se novým okolnostem. Kdybych měla tuto situaci shrnout do jedné věty, použila bych slova Adsona z Melku, z knižního románu Umberta Ecca – Jméno Růže. “Viděli jsme svět jen jako v zrcadle a jako hádanku.” Každý z nás se osobně musel podívat do vlastního zrcadla, které mu ukázalo chyby, nevyřčené problémy, zpětnou rekapitulaci toho, co jsme vykonali a mohli bychom dále pokračovat. Nikdo nevěděl, jak dlouho budeme muset setrvat v tomto stavu, jak hluboko se do nás vryje, či jak dalece změní naše dosavadní myšlení. Všichni se připravovali, sázeli, opírali o lepší, novou budoucnost, která vždy příjde, dokud bude existovat přítomnost. I z toho důvodu dostal projekt název NEW FUTURE, jež v sobě snoubí minulé, to co je přítomné a naznačuje budoucí. Neklade si za cíl komplexně popsat situaci a předkládat řešení, ale poukázat na rozmanitost, výdrž, vůli a odhodlání, které umění dokáže zprostředkovat podobně, jako ono nastavené zrcadlo.</p>
        <p>Pootočením zeměkoule si můžete vybírat projekty a umělecká díla desítky umělců z celého světa, konkrétně Yany Bachynske, Doroty Buczkowske, Felipa Seixase Coelha, Łukasze Horbówa, Teresy Leung, Fernanda Moletty, Nikhila Nagaraje & Vishala Kumaraswamyho, Jeana–Philippa Paumiera, Lauren Ruiz a Agnes Varnai & Tiny Kult.</p>
        <p> -- Anna Königová</p>
        <hr />
        <p>New Future vznikl díky spolupráci: týmu designérů NEON STUDIA, podpory neziskové organizace INDUSTRA, odborné poroty ve složení doc. ak. soch. Jiří Sobotka, M.A. Reinhold Zisser, MgA. Jakub Konvica, doc. MgA. František Kowolowski a kurátorovi projektu Mgr. Anny Königové. Za finanční podpory Ministerstva kultury České republiky.</p>
    </div>
}

export default InfoCard
